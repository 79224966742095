*, :before, :after {
  box-sizing: inherit;
}

html * {
  scroll-behavior: smooth;
}

html, body, div, span, applet, object, iframe, h1, h2, h3, h4, h5, h6, p, blockquote, pre, a, abbr, acronym, address, big, cite, code, del, dfn, em, img, ins, kbd, q, s, samp, small, strike, strong, sub, sup, tt, var, b, u, i, center, dl, dt, dd, ol, ul, li, fieldset, form, label, legend, table, caption, tbody, tfoot, thead, tr, th, td, article, aside, canvas, details, embed, figure, figcaption, footer, header, hgroup, menu, nav, output, ruby, section, summary, time, mark, audio, video {
  font: inherit;
  vertical-align: baseline;
  border: 0;
  margin: 0;
  padding: 0;
}

i {
  font-style: italic;
}

body, html {
  box-sizing: border-box;
  min-width: 100vw;
  min-height: 100vh;
  text-rendering: optimizelegibility;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  margin: 0;
  font-size: 62.5%;
  line-height: 1;
}

main, footer, header, hgroup, menu, nav {
  display: block;
}

ol, ul, li, a {
  text-decoration: none;
  list-style-type: none;
}

a {
  color: inherit;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

section {
  flex-direction: column;
  display: flex;
}

section, div, ul {
  position: relative;
}

object {
  pointer-events: none;
}

:root {
  --space-small: 5%;
  --space-medium: 10%;
  --space-large: 18%;
  --gap-small: 1rem;
  --gap-medium: 2rem;
  --gap-large: 4rem;
  --gap-huge: 8rem;
  --white: #fff;
  --silk: #f5f5f5;
  --silk-shade: #f5f5f580;
  --charcol: #424c4c;
  --charcol-shade: #424c4c85;
  --black: #1c1c1c;
  --icon: 5rem;
  --border-radius: 10px;
  --animation-time: .2s;
  --animation-time-long: 6s;
}

@keyframes pulse {
  0% {
    box-shadow: 0 0 0 0 var(--charcol-shade);
  }
}

.container, .music, .card, .timeline {
  width: 90%;
}

@media (width >= 992px) {
  .container, .music, .card, .timeline {
    max-width: 600px;
  }
}

@media (width >= 768px) {
  .container, .music, .card, .timeline {
    width: 100%;
    max-width: 750px;
  }
}

@media (width >= 1200px) {
  .container, .music, .card, .timeline {
    max-width: 950px;
  }
}

.section, .section__top {
  justify-content: center;
  align-items: center;
  padding: 60px 0;
  display: flex;
}

@media (width >= 768px) {
  .section__top {
    padding: 200px 0 60px;
  }
}

.outlined {
  border: 2px solid var(--charcol-shade);
  transition: border var(--animation-time) ease;
}

.outlined:hover {
  border: 2px solid #fff0;
}

.rounded, .timeline__button, .burger, .nav__list {
  border-radius: 10px;
}

.rounded__large {
  border-radius: 20px;
}

.acrylic, .card__item__content, .timeline__item, .burger, .nav__menu, .nav__list, .hero__content {
  background-blend-mode: exclusion;
  backdrop-filter: blur(24px);
}

.fullframe, .fullframe--hover {
  object-fit: cover;
  width: 100%;
  height: 100%;
  position: absolute;
}

.fullframe--hover {
  transition: transform var(--animation-time);
  transform: scale(1);
}

.fullframe--hover:hover {
  transform: scale(1.1);
}

.button, .card__item__link, .timeline__button {
  background: var(--button-color);
  transition: background var(--animation-time), box-shadow var(--animation-time);
  display: block;
}

.box-shadow, .music__item, .card__item__link, .card__item, .timeline__button, .timeline__item, .nav__list, .hero__content {
  box-shadow: 0 0 2px var(--shadow-color);
}

.hover-pulse:hover, .social__link:hover, .hover-pulse:focus, .social__link:focus {
  animation: .5s pulse;
  box-shadow: 0 0 0 36px #0000;
}

.hover-fill {
  --hover-x: 50%;
  --hover-y: 50%;
  position: relative;
  overflow: hidden;
}

.hover-fill:after {
  content: "";
  top: var(--hover-y);
  left: var(--hover-x);
  background-color: var(--highlight-color);
  width: 0;
  height: 0;
  transition: width var(--animation-time) ease-in-out, height var(--animation-time) ease-in-out;
  z-index: -1;
  border-radius: 50%;
  position: absolute;
  transform: translate(-50%, -50%);
}

.hover-fill:hover:after {
  width: 300%;
  height: 300%;
}

.hover-fill:active {
  background-color: var(--highlight-color);
}

.hover-shadow {
  transition: all var(--animation-time);
}

.hover-shadow:after {
  content: " ";
  opacity: 0;
  width: 100%;
  height: 100%;
  box-shadow: 0 5px 15px var(--accent-color);
  transition: opacity var(--animation-time) ease;
}

.hover-shadow:hover:after {
  opacity: 1;
}

.hover-line {
  box-shadow: inset 0 -2px 0 0 var(--highlight-color);
  transition: all var(--animation-time);
  box-sizing: border-box;
  padding: 0 3px;
  text-decoration: none;
  display: inline-block;
}

.hover-line:hover {
  box-shadow: inset 0 -24px 0 0 var(--highlight-color);
  border: none;
}

.faded {
  opacity: .7;
}

@font-face {
  font-family: basier square;
  src: url("basiersquare-regular-webfont.34c6658e.woff2") format("woff2"), url("basiersquare-regular-webfont.d9f0e475.woff") format("woff"), url("basiersquare-regular-webfont.8fabf11e.ttf") format("truetype");
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: basier square;
  src: url("basiersquare-regularitalic-webfont.1d3036b3.woff2") format("woff2"), url("basiersquare-regularitalic-webfont.3b3e8b19.woff") format("woff"), url("basiersquare-regularitalic-webfont.ad453ecf.ttf") format("truetype");
  font-weight: 400;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: basier square;
  src: url("basiersquare-medium-webfont.4316dc4b.woff2") format("woff2"), url("basiersquare-medium-webfont.6ed17690.woff") format("woff"), url("basiersquare-medium-webfont.66024cc3.ttf") format("truetype");
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: basier circle;
  src: url("basiercircle-medium-webfont.956f9f80.woff2") format("woff2"), url("basiercircle-medium-webfont.545cdc30.woff") format("woff"), url("basiercircle-medium-webfont.7ed6f310.ttf") format("truetype");
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: basier square;
  src: url("basiersquare-semibold-webfont.8f6aa468.woff2") format("woff2"), url("basiersquare-semibold-webfont.0f1ec7c2.woff") format("woff"), url("basiersquare-semibold-webfont.3ba2e24f.ttf") format("truetype");
  font-weight: 600;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: basier circle;
  src: url("basiercircle-semibold-webfont.174241fe.woff2") format("woff2"), url("basiercircle-semibold-webfont.6a595105.woff") format("woff"), url("basiercircle-semibold-webfont.07864c73.ttf") format("truetype");
  font-weight: 600;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: basier square;
  src: url("basiersquare-bold-webfont.19e3e2cd.woff2") format("woff2"), url("basiersquare-bold-webfont.00d01ab0.woff") format("woff"), url("basiersquare-bold-webfont.50d23821.ttf") format("truetype");
  font-weight: 700;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: basier circle;
  src: url("basiercircle-bold-webfont.d71eaf66.woff2") format("woff2"), url("basiercircle-bold-webfont.768ba4e7.woff") format("woff"), url("basiercircle-bold-webfont.bd7151e5.ttf") format("truetype");
  font-weight: 700;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: novela;
  src: url("novela-regular-webfont.3b9605af.woff2") format("woff2"), url("novela-regular-webfont.bbe839b6.woff") format("woff"), url("novela-regular-webfont.e471d460.ttf") format("truetype");
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}

body {
  font-family: basier square, sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
}

body h1, body h2, body h3, body h4 {
  font-family: basier circle, sans-serif;
}

body a, body p, body span {
  line-height: 1.4;
}

body main h2, body main h6, body main p {
  margin: var(--gap-small) 0;
}

.title, .hero__title {
  font-size: 40px;
}

.subtitle, .music > h2, .timeline > h2 {
  font-size: 28px;
}

.subsubtitle, .timeline__content > h3 {
  font-size: 22px;
}

:root, html[data-theme="light"] {
  --background-color: #eee;
  --text-color: #000;
  --border-color: #c7cdd1;
  --button-color: #fff;
  --accent-color: #ffffff78;
  --highlight-color: #efd096;
  --line-color: linear-gradient(to top, #0000 0%, #00000030 2.5%, #00000030 97.5%, #0000 100%);
  --shadow-color: #00000030;
}

html[data-theme="dark"] {
  --background-color: #1c1c1c;
  --text-color: #f5f5f5;
  --border-color: #323232;
  --button-color: #474747;
  --accent-color: #2c2c2c78;
  --highlight-color: #397a95;
  --line-color: linear-gradient(to top, #fff0 0%, #ffffff7e 2.5%, #ffffff7e 97.5%, #fff0 100%);
  --shadow-color: #ffffff7e;
}

html, body {
  background: var(--background-color);
  color: var(--text-color);
  overflow-x: hidden;
}

.themeswitch {
  z-index: 9;
  position: absolute;
  top: 25px;
  right: 25px;
}

.themeswitch__checkbox {
  width: 50px;
  height: 24px;
  display: inline-block;
  position: relative;
}

.themeswitch__checkbox input {
  display: none;
}

.icon-sun, .icon-moon {
  mix-blend-mode: difference;
  display: none;
}

html[data-theme="light"] .icon-sun, html[data-theme="dark"] .icon-moon {
  display: inline-block;
}

.slider {
  cursor: pointer;
  background: var(--highlight-color);
  transition: var(--animation-time);
  border-radius: 34px;
  position: absolute;
  inset: 0;
}

.slider:before {
  content: "";
  width: 18px;
  height: 18px;
  transition: var(--animation-time);
  background: #fff;
  border-radius: 50%;
  position: absolute;
  bottom: 3px;
  left: 3px;
}

input:checked + .slider:before {
  transform: translateX(26px);
}

.hero {
  min-height: 600px;
  display: flex;
}

@media (width >= 768px) {
  .hero {
    flex-direction: row;
    height: 100vh;
  }
}

.hero__background {
  min-height: 550px;
  position: relative;
}

@media (width >= 768px) {
  .hero__background {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
  }
}

.hero__content {
  padding: var(--gap-medium);
  background: var(--accent-color);
  flex-direction: column;
  justify-content: center;
  align-items: center;
  display: flex;
}

.hero__content h1, .hero__content p {
  width: 90%;
  max-width: 450px;
}

@media (width >= 768px) {
  .hero__content h1, .hero__content p {
    width: 100%;
    max-width: 400px;
  }

  .hero__content {
    padding: var(--gap-large);
    border-radius: 20px;
    position: absolute;
    top: 40%;
    right: 10%;
  }
}

.hero__title {
  text-align: left;
  margin-bottom: var(--gap-medium);
  line-height: 1.5;
}

.background {
  object-fit: cover;
  z-index: -1;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  overflow: hidden;
}

.filter--blur {
  filter: blur(48px) opacity(.25);
}

.filter--darken {
  filter: brightness(.8);
}

.book {
  grid-template-columns: 1fr 1fr;
  display: grid;
}

@media (width >= 768px) {
  .book {
    grid-template-columns: 1fr 1fr 1fr 1fr;
  }
}

.book__cover {
  max-height: 400px;
}

.book__item {
  margin: var(--gap-small);
  text-align: center;
  flex-direction: column;
  justify-content: space-between;
  display: flex;
}

.book__title {
  font-size: 2rem;
}

.note {
  grid-template-columns: 1fr;
  display: grid;
}

@media (width >= 768px) {
  .note {
    grid-template-columns: 1fr 1fr;
  }
}

.note__item {
  margin: var(--gap-small);
  position: relative;
  overflow: hidden;
}

.note__figure {
  width: 100%;
  height: 300px;
}

.note__title {
  margin: var(--space-small);
  padding: var(--gap-small) var(--gap-medium);
  background: var(--accent-color);
  position: absolute;
  bottom: 0;
  right: 0;
}

.nav {
  display: block;
}

.nav__list {
  z-index: 9;
  background: var(--accent-color);
  align-items: center;
  display: none;
  position: absolute;
  top: 150px;
  left: 50%;
  overflow: hidden;
  transform: translate(-50%, -50%);
}

@media (width >= 768px) {
  .nav__list {
    display: flex;
  }
}

.nav__list > li {
  text-align: center;
  display: block;
  position: relative;
}

.nav__list > li > a {
  min-width: 150px;
  padding: var(--gap-small) var(--gap-medium);
  font-weight: 500;
  display: inline-block;
}

.nav__list > li:not(:last-child) {
  box-shadow: inset -10px 0 10px -15px var(--shadow-color);
}

.nav__rect {
  background: var(--highlight-color);
  width: 150px;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  transform: translateX(0);
}

.nav__menu {
  visibility: hidden;
  opacity: 0;
  z-index: 8;
  background: var(--accent-color);
  width: 100%;
  transition: var(--animation-time);
  flex-flow: wrap;
  padding: 60px 0;
  display: flex;
  position: fixed;
  top: 0;
  left: 0;
  overflow-x: hidden;
  transform: translateY(-100%);
}

@media (width >= 768px) {
  .nav__menu {
    display: none;
  }
}

.nav__menu > li {
  width: 100%;
  padding: var(--gap-small) 0;
  justify-content: center;
  display: flex;
}

.nav__menu > li > a {
  text-align: center;
  min-width: 120px;
  font-size: 26px;
  font-weight: 500;
  line-height: 2;
}

.nav__menu--visible {
  visibility: visible;
  opacity: 1;
  transform: translateY(0);
}

.burger {
  z-index: 9;
  justify-content: center;
  align-items: center;
  width: 30px;
  height: 30px;
  padding: 20px;
  display: flex;
  position: fixed;
  top: 15px;
  left: 20px;
}

@media (width >= 768px) {
  .burger {
    display: none;
  }
}

.burger__inner {
  background: var(--text-color);
  width: 80%;
  height: 3px;
  transition: var(--animation-time);
  position: absolute;
  left: 10%;
}

.burger__inner:before {
  content: "";
  background: var(--text-color);
  width: 80%;
  height: 3px;
  transition: var(--animation-time);
  position: absolute;
  top: -10px;
  left: 10%;
}

.burger__inner:after {
  content: " ";
  background: var(--text-color);
  width: 80%;
  height: 3px;
  transition: var(--animation-time);
  position: absolute;
  top: 10px;
  left: 10%;
}

.burger__inner--active {
  background-color: #0000;
}

.burger__inner--active:before {
  top: 0;
  transform: rotate(45deg);
}

.burger__inner--active:after {
  top: 0;
  transform: rotate(-45deg);
}

.footer {
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 60px 0;
  display: flex;
}

.social {
  margin: var(--gap-medium) 0;
  flex-direction: row;
  display: flex;
}

.social__link {
  height: var(--icon);
  width: var(--icon);
  border-radius: 10px;
  display: inline-block;
}

.social__link svg {
  width: 100%;
  height: 100%;
  fill: var(--text-color);
}

.social__item {
  margin: 0 var(--gap-small);
  display: block;
}

.icon {
  color: var(--text-color);
  text-align: center;
  z-index: 1;
  width: 50%;
  font-size: 32px;
  line-height: 60px;
}

.dynamic-background {
  animation: var(--animation-time-long) movement linear infinite;
  background-image: radial-gradient(closest-side, #9e5041, #a74f3b00), radial-gradient(closest-side, #894372, #a33b8000), radial-gradient(closest-side, #857741, #8d834c00), radial-gradient(closest-side, #533e9a, #5536a400), radial-gradient(closest-side, #94683c, #965e3b00), radial-gradient(closest-side, #9a553e, #bb5d3d00);
  background-position: -80vmax -80vmax, 60vmax -30vmax, 10vmax 10vmax, -30vmax -10vmax, 50vmax 50vmax, -20vmax, -40vmax;
  background-repeat: no-repeat;
  background-size: 130vmax 130vmax, 80vmax 80vmax, 90vmax 90vmax, 110vmax 110vmax, 90vmax 90vmax, 100vmax, 100vmax;
}

@keyframes movement {
  0%, 100% {
    background-position: -80vmax -80vmax, 60vmax -30vmax, 10vmax 10vmax, -30vmax -10vmax, 50vmax 50vmax;
    background-size: 130vmax 130vmax, 80vmax 80vmax, 90vmax 90vmax, 110vmax 110vmax, 90vmax 90vmax;
  }

  25% {
    background-position: -60vmax -90vmax, 50vmax -40vmax, 0 -20vmax, -40vmax -20vmax, 40vmax 60vmax;
    background-size: 100vmax 100vmax, 90vmax 90vmax, 100vmax 100vmax, 90vmax 90vmax, 60vmax 60vmax;
  }

  50% {
    background-position: -50vmax -70vmax, 40vmax -30vmax, 10vmax 0, 20vmax 10vmax, 30vmax 70vmax;
    background-size: 80vmax 80vmax, 110vmax 110vmax, 80vmax 80vmax, 60vmax 60vmax, 80vmax 80vmax;
  }

  75% {
    background-position: -50vmax -40vmax, 50vmax -30vmax, 20vmax 0, -10vmax 10vmax, 40vmax 60vmax;
    background-size: 90vmax 90vmax, 90vmax 90vmax, 100vmax 100vmax, 90vmax 90vmax, 70vmax 70vmax;
  }
}

.timeline {
  flex-direction: column;
  display: flex;
}

.timeline__line {
  background: var(--line-color);
  border: none;
  width: 2px;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 12px;
}

.timeline > h2 {
  margin: 40px 0 24px;
  font-weight: 600;
}

.timeline__item {
  background: var(--accent-color);
  border-radius: 20px;
  flex-direction: column;
  display: flex;
}

@media (width >= 768px) {
  .timeline__item {
    margin-left: 36px;
  }
}

.timeline__content {
  padding: 24px;
}

.timeline__content > h3 {
  margin: 0 0 12px;
  font-weight: 500;
  line-height: 1.15;
}

.timeline__content:not(:last-child) {
  box-shadow: inset 0 -5px 10px -10px var(--shadow-color);
}

.timeline__button {
  padding: var(--gap-small) var(--gap-large);
  z-index: 1;
  margin-top: 48px;
  font-weight: 500;
}

.card {
  flex-direction: column;
  display: flex;
}

.card__item {
  width: 100%;
  transition: var(--animation-time) ease;
  border-radius: 20px;
  flex-direction: column;
  justify-content: space-between;
  margin: 20px 0;
  display: flex;
  position: relative;
  overflow: hidden;
}

@media (width >= 1200px) {
  .card__item {
    flex-direction: row;
  }
}

.card__item:nth-of-type(n) {
  background: linear-gradient(112deg, #05c0591e, #f9fd501e);
}

.card__item:nth-of-type(2n) {
  background: linear-gradient(72deg, #6bbdcf1e, #268ea81e);
}

.card__item:nth-of-type(3n) {
  background: linear-gradient(90deg, #b559921e, #d79cb11e);
}

.card__item__figure {
  min-width: 450px;
  min-height: 350px;
  max-height: 500px;
  position: relative;
}

.card__item__figure img {
  width: 100%;
  height: 110%;
  position: absolute;
  top: 0;
  left: 0;
}

@media (width >= 1200px) {
  .card__item__figure {
    width: 450px;
    max-height: 550px;
  }

  .card__item__figure img {
    width: 110%;
    height: 100%;
  }
}

.card__item__content {
  padding: var(--gap-medium);
  background: var(--accent-color);
  flex-direction: column;
  display: flex;
  position: relative;
}

@media (width >= 1200px) {
  .card__item__content {
    padding: var(--gap-large);
  }
}

.card__item__content > h3 {
  margin-bottom: var(--gap-small);
  font-size: 26px;
  line-height: 1.15;
}

.card__item__content > p:first-of-type {
  margin-top: 0;
}

.card__item__link {
  min-width: 250px;
  margin-top: var(--gap-small);
  padding: var(--gap-small) var(--gap-medium);
  text-align: center;
  z-index: 0;
  border-radius: 10px;
  align-self: center;
  font-weight: 500;
  display: block;
}

.music {
  flex-direction: column;
  display: flex;
}

.music > h2 {
  margin: 40px 0 24px;
  font-weight: 600;
}

.music__item {
  background: var(--accent-color);
  padding: var(--gap-medium);
  border-radius: 20px;
  flex-direction: column;
  display: flex;
}

.music__item > p:first-of-type {
  margin-top: 0;
}

.music__box {
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  display: flex;
}

.music__box > audio {
  width: 100%;
  margin: 4px 0;
}

.music__box > iframe {
  background: var(--button-color);
  border-radius: 10px;
  width: 100%;
  margin: 6px 0;
}

.music__box > .bandcamp {
  max-width: 550px;
  height: 208px;
}
/*# sourceMappingURL=index.c2a38c73.css.map */
