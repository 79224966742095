.hero {
    display: flex;
    min-height: 600px;
  
    @include respond-to('landscape') {
      height: 100vh;
      flex-direction: row;
    }

    &__background {
      position: relative;
      min-height: 550px;

      @include respond-to('landscape') {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
      }
    }
  
    &__content {
      @extend .acrylic;
      @extend .box-shadow;
    
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      padding: var(--gap-medium);
      background: var(--accent-color);

      h1, p {
        width: 90%;
        max-width: 450px;

        @include respond-to('landscape') {
          width: 100%;
          max-width: 400px;
        }
      }

      @include respond-to('landscape') {
        position: absolute;
        top: 40%;
        right: 10%;
        padding: var(--gap-large);
        border-radius: $border-radius-large;
      }
    }
  
    &__title {
      @extend .title;

      text-align: left;
      line-height: 1.5;
      margin-bottom: var(--gap-medium);
    }
}