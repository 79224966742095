:root, html[data-theme="light"] {
  --background-color: #eeeeee;
  --text-color: #000;
  --border-color: #c7cdd1;
  --button-color: #fff;
  --accent-color: #ffffff78;
  --highlight-color: #efd096;
  --line-color: linear-gradient(to top, #00000000 0%, #00000030 2.5%, #00000030 97.5%, #00000000 100%);
  --shadow-color: #00000030;
}

html[data-theme="dark"] {
  --background-color: #1c1c1c;
  --text-color: #f5f5f5;
  --border-color: #323232;
  --button-color: #474747;
  --accent-color: #2c2c2c78;
  --highlight-color: #397a95;
  --line-color: linear-gradient(to top, #ffffff00 0%, #ffffff7e 2.5%, #ffffff7e 97.5%, #ffffff00 100%);
  --shadow-color: #ffffff7e;
}

html, body {
	background: var(--background-color);
  color: var(--text-color);
  overflow-x: hidden;
}

.themeswitch {
  position: absolute;
  top: 25px;
  right: 25px;
  z-index: 9;

  &__checkbox {
    position: relative;
    display: inline-block;
    width: 50px;
    height: 24px;

    input {
      display: none;
    }
  }
}

.icon-sun, .icon-moon {
  display: none;
  mix-blend-mode: difference;
}

html[data-theme="light"] .icon-sun {
  display: inline-block;
}

html[data-theme="dark"] .icon-moon {
  display: inline-block;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: var(--highlight-color);
  transition: var(--animation-time);
  border-radius: 34px;

  &::before {
    position: absolute;
    content: "";
    height: 18px;
    width: 18px;
    left: 3px;
    bottom: 3px;
    border-radius: 50%;
    background: $color-white;
    transition: var(--animation-time);
  }
}

input:checked + .slider {
  &::before {
    transform: translateX(26px);
  }
}