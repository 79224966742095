.nav {
  display: block;

  &__list {
    // only shows in desktop
    @extend .acrylic;
    @extend .rounded;
    @extend .box-shadow;
    
    position: absolute;
    display: none;
    z-index: 9;
    top: 150px;
    left: 50%;
    transform: translate(-50%, -50%);
    background: var(--accent-color);
    align-items: center;
    overflow: hidden;

    @include respond-to('landscape') {
      display: flex;
    }

    > li {
      display: block; 
      position: relative;
      text-align: center;

      > a {
        display: inline-block;
        min-width: 150px;
        padding: var(--gap-small) var(--gap-medium);
        font-weight: 500;
      }

      &:not(:last-child) {
        // add subtitle separation effect
        box-shadow: inset -10px 0 10px -15px var(--shadow-color);
      }
    }
  }

  &__rect {
    position: absolute;
    top: 0;
    left: 0;
    transform: translateX(0);
    width: 150px;
    height: 100%;
    background: var(--highlight-color);
  }

  &__menu {
    @extend .acrylic;

    display: flex;
    position: fixed;
    visibility: hidden;
    opacity: 0;
    z-index: 8;
    top: 0;
    left: 0;
    width: 100%;
    padding: 60px 0;
    background: var(--accent-color);
    transform: translateY(-100%);
    transition: var(--animation-time);
    flex-direction: row;
    flex-wrap: wrap;
    overflow-x: hidden;

    @include respond-to('landscape') {
      display: none;
    }

    > li {
      display: flex;
      width: 100%;
      justify-content: center;
      padding: var(--gap-small) 0;

      > a {
        text-align: center;
        font-weight: 500;
        min-width: 120px;
        font-size: 26px;
        line-height: 2;
      }
    }
  
    &--visible {
      visibility: visible;
      opacity: 1;
      transform: translateY(0);
    }
  }
}

.burger{
  @extend .acrylic;
  @extend .rounded;

  position: fixed;
  z-index: 9;
  top: 15px;
  left: 20px;
  width: 30px;
  height: 30px;
  padding: 20px;
  display: flex;
  justify-content: center;
  align-items: center;

  @include respond-to('landscape') {
    display: none;
  }

  &__inner {
    position: absolute;
    width: 80%;
    left: 10%;
    height: 3px;
    background: var(--text-color);
    transition: var(--animation-time);
  
    &::before {
      content: '';
      position: absolute;
      width: 80%;
      left: 10%;
      top: -10px;
      height: 3px;
      background: var(--text-color);
      transition: var(--animation-time);
    }
  
    &::after {
      position: absolute;
      content: ' ';
      width: 80%;
      left: 10%;
      top: 10px;
      height: 3px;
      background: var(--text-color);
      transition: var(--animation-time);
    }
  
    &--active {
      background-color: transparent;
  
      &::before {
        transform: rotate(45deg);
        top: 0;
      }
  
      &::after {
        transform: rotate(-45deg);
        top: 0;
      }
    }
  }
}