
$faint-backgrounds: (
  'n': linear-gradient(112deg, #05c0591e, #f9fd501e),
  '2n': linear-gradient(72deg, #6bbdcf1e, #268ea81e),
  '3n': linear-gradient(90deg, #b559921e, #d79cb11e)
);

$button-backgrounds: (
  'n': linear-gradient(90deg, #00bd561a, #f9fd501a),
  '2n': linear-gradient(90deg, #26baee1a, #9fe8fa),
  '3n': linear-gradient(90deg, #55968f1a, #8acbbb1a)
);

.card {
  @extend .container;

	display: flex;
	flex-direction: column;

  &__item {
    @extend .box-shadow;
    
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    border-radius: $border-radius-large;
    overflow: hidden;
    width: 100%;
    margin: 20px 0;
    transition: var(--animation-time) ease;

    @include respond-to('desktop') {
      flex-direction: row;
    }

    @each $faint-backgrounds, $i in $faint-backgrounds {
      &:nth-of-type(#{$faint-backgrounds}) {
        background: $i;
      }
    }

    &__figure {
      position: relative;
      min-width: 450px;
      min-height: 350px;
      max-height: 500px;

      img {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 110%;
      }

      @include respond-to('desktop') {
        width: 450px;
        max-height: 550px;

        img {
          width: 110%;
          height: 100%;
        }
      }
    }

    &__content {
      @extend .acrylic;
  
      position: relative;
      display: flex;
      flex-direction: column;
      padding: var(--gap-medium);
      background: var(--accent-color);
  
      @include respond-to('desktop') {
        padding: var(--gap-large);
      }

      > h3 {
        font-size: 26px;
        margin-bottom: var(--gap-small);
        line-height: 1.15;
      }

      > p:first-of-type {
        margin-top: 0;
      }
    }

    &__link {
      @extend .button;
      @extend .box-shadow;

      display: block;
      min-width: 250px;
      align-self: center;
      border-radius: $border-radius;
      margin-top: var(--gap-small);
      padding: var(--gap-small) var(--gap-medium);
      text-align: center;
      font-weight: 500;
      z-index: 0;
    }
  }
}
