@font-face {
	font-family: 'basier square';
	src: url('../fonts/basiersquare-regular-webfont.woff2') format('woff2'),
		 url('../fonts/basiersquare-regular-webfont.woff') format('woff'),
		 url('../fonts/basiersquare-regular-webfont.ttf') format('truetype');
	font-weight: 400;
	font-style: normal;
	font-display: swap;
}

@font-face {
	font-family: 'basier square';
	src: url('../fonts/basiersquare-regularitalic-webfont.woff2') format('woff2'),
         url('../fonts/basiersquare-regularitalic-webfont.woff') format('woff'),
         url('../fonts/basiersquare-regularitalic-webfont.ttf') format('truetype');
	font-weight: 400;
	font-style: italic;
	font-display: swap;
}

@font-face {
	font-family: 'basier square';
	src: url('../fonts/basiersquare-medium-webfont.woff2') format('woff2'),
         url('../fonts/basiersquare-medium-webfont.woff') format('woff'),
         url('../fonts/basiersquare-medium-webfont.ttf') format('truetype');
	font-weight: 500;
	font-style: normal;
	font-display: swap;
}

@font-face {
	font-family: 'basier circle';
	src: url('../fonts/basiercircle-medium-webfont.woff2') format('woff2'),
         url('../fonts/basiercircle-medium-webfont.woff') format('woff'),
         url('../fonts/basiercircle-medium-webfont.ttf') format('truetype');
	font-weight: 500;
	font-style: normal;
	font-display: swap;
}

@font-face {
	font-family: 'basier square';
	src: url('../fonts/basiersquare-semibold-webfont.woff2') format('woff2'),
         url('../fonts/basiersquare-semibold-webfont.woff') format('woff'),
         url('../fonts/basiersquare-semibold-webfont.ttf') format('truetype');
	font-weight: 600;
	font-style: normal;
	font-display: swap;
}

@font-face {
	font-family: 'basier circle';
	src: url('../fonts/basiercircle-semibold-webfont.woff2') format('woff2'),
         url('../fonts/basiercircle-semibold-webfont.woff') format('woff'),
         url('../fonts/basiercircle-semibold-webfont.ttf') format('truetype');
	font-weight: 600;
	font-style: normal;
	font-display: swap;
}

@font-face {
	font-family: 'basier square';
	src: url('../fonts/basiersquare-bold-webfont.woff2') format('woff2'),
         url('../fonts/basiersquare-bold-webfont.woff') format('woff'),
         url('../fonts/basiersquare-bold-webfont.ttf') format('truetype');
	font-weight: 700;
	font-style: normal;
	font-display: swap;
}

@font-face {
	font-family: 'basier circle';
	src: url('../fonts/basiercircle-bold-webfont.woff2') format('woff2'),
         url('../fonts/basiercircle-bold-webfont.woff') format('woff'),
         url('../fonts/basiercircle-bold-webfont.ttf') format('truetype');
	font-weight: 700;
	font-style: normal;
	font-display: swap;
}

@font-face {
	font-family: 'novela';
	src: url('../fonts/novela-regular-webfont.woff2') format('woff2'),
         url('../fonts/novela-regular-webfont.woff') format('woff'),
         url('../fonts/novela-regular-webfont.ttf') format('truetype');
	font-weight: 400;
	font-style: normal;
	font-display: swap;
}

body {
	font-family: 'basier square', sans-serif;
	font-style: normal;
	font-weight: 400;
	font-size: 16px;

	h1, h2, h3, h4 {
		font-family: 'basier circle', sans-serif;
	}

	a, p, span {
		line-height: 1.4;
	}
  
	main {
		h2, h6, p {
			margin: var(--gap-small) 0;
		}
	}
}

.title {
	font-size: 40px;
}
  
.subtitle {
	font-size: 28px;
}

.subsubtitle {
	font-size: 22px;
}
