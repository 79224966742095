.timeline {
  @extend .container;

  display: flex;
  flex-direction: column;

  &__line {
    position: absolute;
    top: 0;
    left: 12px;
    bottom: 0;
    width: 2px;
    background: var(--line-color);
    border: none;
  }

  > h2 {
    @extend .subtitle;

    margin: 40px 0 24px 0;
    font-weight: 600;
  }

  &__item {
    @extend .acrylic;
    @extend .box-shadow;

    display: flex;
    flex-direction: column;
    background: var(--accent-color);
    border-radius: $border-radius-large;

    @include respond-to('landscape') {
      margin-left: 36px;
    }
  }

  &__content {
    padding: 24px;

    > h3 {
      @extend .subsubtitle;

      line-height: 1.15;
      font-weight: 500;
      margin: 0 0 12px 0;
    }

    &:not(:last-child) {
      // add subtitle separation effect
      box-shadow: inset 0 -5px 10px -10px var(--shadow-color);
    }
  }

  &__button {
    @extend .rounded;
    @extend .button;
    @extend .box-shadow;
  
    font-weight: 500;
    margin-top: 48px;
    padding: var(--gap-small) var(--gap-large);
    z-index: 1;
  }
}