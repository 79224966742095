:root {
  --space-small: 5%;
  --space-medium: 10%;
  --space-large: 18%;
  --gap-small: 1rem;
  --gap-medium: 2rem;
  --gap-large: 4rem;
  --gap-huge: 8rem;
  --white: #fff;
  --silk: #f5f5f5;
  --silk-shade: #f5f5f580;
  --charcol: #424c4c;
  --charcol-shade: #424c4c85;
  --black: #1c1c1c;
  --icon: 5rem;
  --border-radius: 10px;
  --animation-time: .2s;
  --animation-time-long: 6s;
}

$color-white: #fff;

$border-radius: 10px;
$border-radius-large: 20px;


@keyframes pulse {
  0% {
    box-shadow: 0 0 0 0 var(--charcol-shade);
  }
}

.container {
  width: 90%;
  
  @include respond-to('tablet') {
    max-width: 600px;
  }

  @include respond-to('landscape') {
    width: 100%;
    max-width: 750px;
  }

  @include respond-to('desktop') {
    max-width: 950px;
  }
}

.section {
  padding: 60px 0;
  display: flex;
  align-items: center;
  justify-content: center;

  &__top {
    @extend .section;

    @include respond-to('landscape') {
      padding: 200px 0 60px 0;
    }
  }
}

.outlined {
  border: 2px solid var(--charcol-shade);
  transition: border var(--animation-time) ease;

  &:hover {
    border: 2px solid #ffffff00;
  }
}

.rounded {
	border-radius: $border-radius;

  &__large {
    border-radius: $border-radius-large;
  }
}

.acrylic {
  background-blend-mode: exclusion;
	-webkit-backdrop-filter: blur(24px);
	backdrop-filter: blur(24px);
}

.fullframe {
  position: absolute;
  object-fit: cover;
	width: 100%;
	height: 100%;

  &--hover {
    @extend .fullframe;
    transition: transform var(--animation-time);
    transform: scale(1);

    &:hover {
      transform: scale(1.1);
    }
  }
}

.button {
  display: block;
  background: var(--button-color);
  transition: background var(--animation-time), box-shadow var(--animation-time);
}

.box-shadow {
  box-shadow: 0 0 2px var(--shadow-color);
}

.hover-pulse {
  &:hover, &:focus {
    animation: pulse .5s;
    box-shadow: 0 0 0 36px transparent;
  }
}

.hover-fill {
  position: relative;
  overflow: hidden;
  
  --hover-x: 50%;
  --hover-y: 50%;

  &::after {
    content: '';
    position: absolute;
    top: var(--hover-y);
    left: var(--hover-x);
    width: 0;
    height: 0;
    border-radius: 50%;
    background-color: var(--highlight-color);
    transition: width var(--animation-time) ease-in-out, height var(--animation-time) ease-in-out;
    transform: translate(-50%, -50%);
    z-index: -1;
  }

  &:hover::after {
    width: 300%;
    height: 300%;
  }

  &:active {
    background-color: var(--highlight-color);
  }
}

.hover-shadow {
  transition: all var(--animation-time);

  &::after {
    content: ' ';
    width: 100%;
    height: 100%;
    opacity: 0;
    box-shadow: 0 5px 15px var(--accent-color);
    transition: opacity var(--animation-time) ease;
  }

  &:hover::after {
    opacity: 1;
  }
}

.hover-line {
  text-decoration: none;
  box-shadow: inset 0 -2px 0 0 var(--highlight-color);
  display: inline-block;
  transition: all var(--animation-time);
  padding: 0 3px;
  box-sizing: border-box;

  &:hover {
    border: none;
    box-shadow: inset 0 -24px 0 0 var(--highlight-color);
  }
}

.faded {
	opacity: .7;
}