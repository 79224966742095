$breakpoints: ('mobile': (min-width: 478),
  'landscape': (min-width: 768px),
  'tablet': (min-width: 992px),
  'desktop': (min-width: 1200px),
  'ultrawide': (min-width: 2000px)) !default;

@mixin dynamic-border-radius($value, $breakpoint) {
  & {
    border-radius: #{"max(0px, min(#{$value}, 100% - #{$breakpoint} + 1px) * 9999) / #{$value}"};
  }
}

@mixin respond-to($breakpoint) {
  @if map-has-key($breakpoints, $breakpoint) {
    @media #{inspect(map-get($breakpoints, $breakpoint))} {
      @content;
    }
  }

  @else {
    @warn 'Unfortunately, no value could be retrieved.';
  }
}