.background {
  object-fit: cover;
  z-index: -1;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
}

.filter--blur {
  filter: blur(48px) opacity(.25);
}

.filter--darken {
  filter: brightness(.8);
}

.book {
  display: grid;
  grid-template-columns: 1fr 1fr;

  @include respond-to('landscape') {
    grid-template-columns: 1fr 1fr 1fr 1fr;
  }

  &__cover {
    max-height: 400px;
  }
  
  &__item {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    margin: var(--gap-small);
    text-align: center;
  }

  &__title {
    font-size: 2rem;
  }
}

.note {
	display: grid;
  grid-template-columns: 1fr;

	@include respond-to('landscape') {
		grid-template-columns: 1fr 1fr; 
	}

  &__item {
    position: relative;
		overflow: hidden;
		margin: var(--gap-small);
  }

  &__figure {
    width: 100%;
    height: 300px;
  }

  &__title {
    position: absolute;
    bottom: 0;
    right: 0;
    margin: var(--space-small);
    padding: var(--gap-small) var(--gap-medium);
    background: var(--accent-color);
  }
}